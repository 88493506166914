<template>
  <div class="subject-container">
    <div class="subject-left">
      <div v-for="(data, index) in questionList" :key="data.name" style="background: #F0F0F0">
        <div class="total">
          <p>{{ data.name }}</p>
          <p style="color: #448BFF">
            {{ data.questionForms.length }}
          </p>
          <p>题</p>
          <el-input
            maxLength="3"
            style="margin-right: 5px"
            oninput="value=value.replace(/[^\d.]/g,'')"
            v-model="data.totalScore"
            @blur="setBigTopicPoint(data.totalScore, index)"
            @keydown.enter.native="setBigTopicPoint(data.totalScore, index)"
          />
          <p>分</p>
        </div>
        <div v-for="(form, findex) in data.questionForms" style="margin-bottom: 10px;background: #FFFFFF" :key="findex">
          <div class="top-info">
            <div>
              <p class="inactive">{{ ytConstant.difficultType.getLabel(form.difficulty) }}</p>
              <p slot="reference" class="popover-reference">使用{{ form.useCount || 0 }}次</p>
              <p slot="reference" class="popover-reference" style="display: flex;align-items: center;">
                设置分数
                <el-input
                  maxLength="3"
                  oninput="value=Number(value.replace(/[^\d.]/g,''))"
                  v-model="form.points"
                  @blur="setSmallTopicPoint(form.points, index, findex)"
                  @keydown.enter.native="setSmallTopicPoint(form.points, index, findex)"
                  style="margin: 0 6px"
                />
                分
              </p>
            </div>
            <div style="cursor: pointer;">
              <span style="margin-right: 12px" @click="form.status = !form.status"> {{ form.status ? '收起' : '查看' }}解析 </span>
              <span @click="toDelete(form, index, findex)">
                移除
              </span>
            </div>
          </div>
          <div class="question-info" :id="`anchor-${form.id}`">
            <div class="stem">
              <span>{{ index + 1 }}.</span>
              <div class="title" v-html="form.stem"></div>
            </div>
            <component :is="questionComponents[form.questionType]" :question="form" style="margin-top: 16px" />
            <div v-show="form.status" class="correct-answer">
              <div style="flex-shrink: 0;letter-spacing: 1px">正确答<span style="letter-spacing: 5px">案:</span></div>
              <div style="white-space: break-spaces;" v-html="form.questionAnswer"></div>
            </div>
            <div v-show="form.status" style="display: flex;flex-direction: column">
              <div style="flex-shrink: 0">
                <span style="letter-spacing: 12px">解</span>
                <span style="letter-spacing: 5px">析:</span>
                <span v-html="form.analysis"></span>
              </div>
              <div class="tag-and-knowledge" ref="tagAndKnowledge">
                <div class="tk-item tk-select-item">
                  <span style="letter-spacing: 12px">标</span>
                  <span style="letter-spacing: 5px">签:</span>
                  <template v-for="(tag, tIndex) in form.tags">
                    <div :key="tIndex">
                      <el-tooltip effect="dark" :disabled="tag.name.length < 4" :content="tag.name" placement="top" :open-delay="1000">
                        <div class="tk-point" :key="tIndex" v-if="tIndex < 4">
                          {{ tag.name }}
                        </div>
                      </el-tooltip>
                    </div>
                  </template>
                  <el-popover
                    placement="top"
                    width="202"
                    trigger="hover"
                    title="题目标签"
                    v-if="form.hasOwnProperty('tags') && form.tags && 4 < form.tags.length"
                  >
                    <div class="tk-select-item tab-list" style="margin-bottom: 5px">
                      <template v-for="(tagPop, tPopIndex) in form.tags">
                        <div :key="tPopIndex">
                          <el-tooltip effect="dark" :disabled="tagPop.name.length < 4" :content="tagPop.name" placement="top" :open-delay="1000">
                            <div class="tk-point" :key="tPopIndex" v-if="tPopIndex >= 4" style="margin-bottom: 5px">
                              {{ tagPop.name }}
                            </div>
                          </el-tooltip>
                        </div>
                      </template>
                    </div>
                    <YTIcon :href="'#icon-gengduo1'" slot="reference" style="margin-right: 8px" />
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subject-right" :style="{ width: !shrink ? '571px' : '30px' }">
      <YTIcon
        :href="'#icon-shouqi'"
        class="shrinkIcon"
        @click="shrink = !shrink"
        :style="{
          fontSize: '18px',
          transform: shrink ? 'rotate(0deg)' : 'rotate(180deg)',
          top: isPackUp ? '50%' : '64%'
        }"
      />
      <div class="right-container">
        <div class="top" v-show="!shrink">
          <p>
            练习总量<span>{{ questionNum }}</span
            >题
          </p>
          <p>
            练习总分<span>{{ totalScore }}</span
            >分
          </p>
        </div>
        <div class="info" v-show="!shrink">
          <div v-for="(data, index) in questionList" :key="data.name">
            <div class="label">
              <div class="total">
                <p></p>
                <span>{{ data.name }}</span>
                <el-input
                  maxLength="3"
                  oninput="value=Number(value.replace(/[^\d.]/g,''))"
                  v-model="data.totalScore"
                  @blur="setBigTopicPoint(data.totalScore, index)"
                  @keydown.enter.native="setBigTopicPoint(data.totalScore, index)"
                />
                <span>分</span>
              </div>
              <YTIcon @click="delQuestion(index)" :href="'#icon-shanchu'" style="width: 15px;height: 15px;fill: #999" />
            </div>
            <div class="serial-number">
              <p
                :style="{ background: form.id === selectRowId ? '#448bff' : '' }"
                v-for="(form, findex) in data.questionForms"
                @click="checkQuestion(form.id)"
              >
                {{ findex + 1 >= 10 ? findex + 1 : `0${findex + 1}` }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrueOrFalse from '@components/manage/question/list/questions/TrueOrFalse'
import Choice from '@components/manage/question/list/questions/Choice'
import Code from '@components/manage/question/list/questions/Code'
import YTIcon from '@components/common/YTIcon'
export default {
  name: 'SubjectShow',
  components: { YTIcon },
  props: ['isPackUp'],
  data() {
    return {
      status: false, //区别修改和新增
      shrink: false,
      questionNum: 0, //总数
      totalScore: 0, //总分
      selectRowId: 0,
      questionList: [],
      questionDetail: [], //修改返回的题目
      questionComponents: [TrueOrFalse, Choice, Choice, null, null, Code] //题目组件
    }
  },
  methods: {
    //修改数据展示所有信息
    setInfo(info) {
      //扁平化数组
      this.status = true
      let section = info.sectionVOS
        .map(res => {
          return res.questionVOS
        })
        .reduce((x, y) => x.concat(y), [])
      //取出questionDetail
      for (const sectionElement of section) {
        this.$set(sectionElement.questionDetail, 'points', sectionElement.points)
      }
      this.questionDetail = section.map(data => data.questionDetail)
      this.setList(this.questionDetail, null, true)
    },
    //删除所有题目
    delQuestion(index) {
      this.questionList.splice(index, 1)
      if (!this.questionList.length) {
        this.$parent.subjectStatus = false
      }
      this.setTotalScore(this.questionList)
    },
    //修改总分
    setBigTopicPoint(data, index) {
      const len = this.questionList[index].questionForms.length
      const num = Math.floor(data / len) === 0 ? 0.5 : Math.floor(data / len)
      for (let element of this.questionList[index].questionForms) {
        this.$set(element, 'points', num)
        this.$forceUpdate()
      }
      this.setTotalScore(this.questionList)
    },
    //设置单个分数
    setSmallTopicPoint(data, index, findex) {
      const num = Math.floor(data) === 0 ? 0.5 : Math.floor(data)
      this.$set(this.questionList[index].questionForms[findex], 'points', num)
      this.$forceUpdate()
      this.setTotalScore(this.questionList)
    },
    //删除单个题目
    toDelete(form, index, findex) {
      if (this.questionList[index].questionForms.length - 1) {
        this.questionList[index].questionForms.splice(findex, 1)
      } else {
        this.questionList.splice(index, 1)
      }
      if (!this.questionList.length) {
        this.$parent.subjectStatus = false
      }
      this.setTotalScore(this.questionList)
    },
    //定位题目
    checkQuestion(id) {
      this.selectRowId = id
      document.querySelector(`#anchor-${id}`).scrollIntoView({ behavior: 'smooth' })
    },
    //选择的题目进行组合
    async setList(data, ruleId, status) {
      let payload = {
        sectionForms: []
      }
      if (ruleId) {
        this.$set(payload, 'ruleId', ruleId)
      }
      if (this.status) {
        data = await this.Deduplication([...data, ...this.questionDetail])
      } else {
        data = await this.Deduplication(data)
      }
      let array = data
        .map(res => {
          return this.ytConstant.questionType.getLabel(res.questionType)
        })
        .filter((item, index, arr) => arr.indexOf(item) === index)
      for (const arrayElement of array) {
        payload.sectionForms.push({
          name: arrayElement,
          questionForms: [],
          totalScore: 0
        })
      }
      let info = data.map(res => {
        res['type'] = this.ytConstant.questionType.getLabel(res.questionType)
        return res
      })
      for (const infoElement of info) {
        for (const payloadElement of payload.sectionForms) {
          if (payloadElement.name === infoElement.type) {
            this.$set(infoElement, 'level', 1)
            this.$set(infoElement, 'score', 1)
            this.$set(infoElement, 'status', false)
            if (!status) {
              this.$set(infoElement, 'points', 1)
            }
            payloadElement.questionForms.push(infoElement)
          }
        }
      }
      this.questionList = [...payload.sectionForms]
      this.setTotalScore(this.questionList)
    },
    //修改重新添加题目进行去重
    async Deduplication(data) {
      let map = new Map()
      for (let item of data) {
        if (!map.has(item.id)) {
          map.set(item.id, item)
        }
      }
      return [...map.values()]
    },
    //题目总数和总成绩
    setTotalScore(data) {
      let questionNum = 0
      let totalScore = 0
      for (const datum of data) {
        let num = 0
        for (const form of datum.questionForms) {
          num += Number(form.points)
          questionNum++
          totalScore += Number(form.points)
        }
        datum.totalScore = num
      }
      this.questionNum = questionNum
      this.totalScore = totalScore
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';
@import '~@/theme/filterDropdownMenu.less';
.subject-container {
  display: flex;
  height: 100%;
  .subject-left {
    min-width: 530px;
    width: 100%;
    margin-right: 9px;
    overflow: auto;
    background: #ffffff;
    border-radius: 6px;
    .total {
      display: flex;
      align-items: center;
      height: 50px;
      background: #f0f0f0;
      border-radius: 6px 6px 0 0;
      padding: 13px 0 13px 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #282c3d;
      p {
        margin-right: 6px;
      }
      ::v-deep .el-input {
        width: 60px;
        height: 24px;
        border-radius: 3px;
        .el-input__inner {
          height: 24px;
          line-height: 24px;
        }
      }
    }
    .question-info {
      box-sizing: border-box;
      padding: 10px 0 20px 20px;
      white-space: break-spaces;
      .stem {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #000000;
        .title {
          word-break: break-word;
          word-wrap: break-word;
          white-space: break-spaces;
        }
      }
      .correct-answer {
        display: flex;
        color: #06c281;
        margin: 16px 0;
      }
      .tag-and-knowledge {
        .flexStyle(flex, flex-start, center);
        margin: 14px 0 0;
        padding-bottom: 15px;
        height: 20px;
        .tk-item {
          .flexStyle(flex, flex-start, center);
          width: calc(100% / 2);
        }
      }
    }
    .top-info {
      .flexStyle(flex, space-between, center);
      font-size: @small;
      padding: 20px 20px 10px 20px;
      border-bottom: 1px solid #f0f0f0;
      div {
        .flexStyle(flex, flex-start, center);
      }
      .el-input {
        max-width: 60px;
        ::v-deep .el-input__inner {
          height: 20px;
          border: 1px solid #d9d9d9;
          font-size: 12px;
          font-weight: 400;
        }
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 12px;
        margin-right: 12px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
      .inactive {
        cursor: initial;
        &:hover {
          color: #000;
        }
      }
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #438bff;
      }
      .button {
        color: #666666;
        margin-left: 17px;
        cursor: pointer;
        &:hover {
          color: #448bff;
          svg {
            color: #448bff;
          }
        }
        svg {
          font-size: 14px;
          margin-right: 6px;
        }
      }
    }
  }
  .subject-right {
    overflow-y: auto;
    position: relative;
    background: #ffffff;
    border-radius: 6px 0 0 6px;
    .shrinkIcon {
      position: fixed;
      color: #999999;
    }
    .right-container {
      .top {
        position: fixed;
        z-index: 5;
        display: flex;
        justify-content: space-between;
        height: 46px;
        padding: 12px 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        color: #282c3d;
        border-bottom: 1px solid #e2e4e8;
        min-width: 359px;
        background: #ffffff;
        span {
          color: #448bff;
        }
      }
      .info {
        padding: 46px 20px 0 35px;
        overflow-y: auto;
        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          padding-top: 10px;
          .total {
            display: flex;
            align-items: center;
            p {
              width: 3px;
              height: 10px;
              background: #438bff;
              opacity: 1;
              border-radius: 2px;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              line-height: 22px;
              color: #282c3d;
              margin-left: 6px;
            }
            ::v-deep .el-input {
              margin-left: 6px;
              width: 58px;
              height: 20px;
              background: #ffffff;
              border-radius: 3px;
              .el-input__inner {
                line-height: 20px;
                height: 20px;
              }
            }
          }
        }
      }
      .serial-number {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid #f0f0f0;
        width: 329px;
        p {
          width: 24px;
          height: 24px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          border-radius: 3px;
          margin: 0 18px 10px 0;
          text-align: center;
          line-height: 21px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}
.tk-point {
  max-width: 70px;
  height: 22px;
  line-height: 22px;
  border-color: #edf4ff;
  border-radius: 2px;
  color: #448bff;
  margin-right: 8px;
  padding: 0 8px;
  background-color: #edf4ff;
  font-size: @small;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  i {
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
  }
  &:focus {
    border-color: #448bff;
  }
}
</style>
