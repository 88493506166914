<template>
  <div class="yt-main" style="padding: 10px 10px 78px 10px;">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/course' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/course/detail' }"><span class="itClass">课程详情</span></el-breadcrumb-item>
        <el-breadcrumb-item>新增练习</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container add-info">
      <div class="add-top" :style="{ height: subjectStatus ? '' : '100%' }">
        <div class="title">
          <p>新增手动练习</p>
          <div class="button" @click="isPackUp = !isPackUp">
            <span v-text="isPackUp ? '展开' : '收起'"></span>
            <YTIcon :href="'#icon-open'" :style="{ transform: isPackUp ? 'rotate(0deg)' : 'rotate(180deg)' }" />
          </div>
        </div>
        <el-form class="yt-form" :model="dateForm" ref="validateForm" v-show="!isPackUp">
          <el-form-item label="新增练习:" label-width="135px">
            <el-radio-group v-model="dateForm.type" :disabled="disabled">
              <el-radio :label="0">本章练习</el-radio>
              <el-radio :label="1">小节练习</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否允许多次练习:" label-width="185px">
            <el-radio-group v-model="dateForm.isRepeat">
              <el-radio :label="true">
                是
                <el-tooltip class="item" effect="dark" content="允许用户多次参加该练习，会以用户最好成绩为准。" placement="top-start">
                  <YTIcon style="color: #999999;margin-right: 10px;font-size: 14px" :href="'#icon-tishi3'" />
                </el-tooltip>
              </el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="小节名称"
            label-width="135px"
            v-if="dateForm.type === 1"
            prop="sectionId"
            :rules="{
              required: true,
              message: '请选择小节名称',
              trigger: 'change'
            }"
          >
            <el-select v-model="dateForm.sectionId" value-key="sectionId" placeholder="请选择小节名称" :disabled="disabled">
              <el-option v-for="item in options" :key="item.sectionId" :label="item.name" :value="item.sectionId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="练习名称"
            label-width="135px"
            prop="name"
            :rules="{
              required: true,
              message: '请输入练习名称',
              trigger: 'blur'
            }"
          >
            <el-input v-model="dateForm.name" maxlength="30" placeholder="请输入练习名称"></el-input>
          </el-form-item>
          <el-form-item
            label="练习时长"
            label-width="135px"
            prop="limitTime"
            :rules="{
              required: true,
              message: '请输入练习时长',
              trigger: 'blur'
            }"
          >
            <el-input maxLength="3" oninput="value=Number(value.replace(/[^\d.]/g,''))" v-model="dateForm.limitTime" placeholder="请输入练习时长">
              <template slot="append">分钟</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label=" "
            :rules="{
              required: true
            }"
            label-width="80px"
          >
            <el-button class="question-button" @click="selectQuestions">选择试题</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="add-fun" :style="{ height: setHeight, display: subjectStatus ? 'flex' : 'none' }">
        <div class="subject-list" style="margin-top: 20px">
          <SubjectShow ref="SubjectShow" :isPackUp="isPackUp" :type="dateForm.type" />
        </div>
      </div>
    </div>
    <div class="button">
      <el-button class="el-button--default el-button--style" @click="$router.go(-1)">取消</el-button>
      <el-button @click="submitQuestion" type="primary">保存</el-button>
    </div>
    <el-dialog
      class="yt-dialog question-choose-dialog"
      title="选择题目"
      :visible.sync="choiceQuestions"
      width="1080px"
      :fullscreen="fullscreen"
      :destroy-on-close="true"
    >
      <Manual ref="manual" :state="choiceQuestions" :fullscreen="fullscreen" :notQuestionType="notQuestionType" />
      <div slot="footer" class="footer" style="justify-content: space-between">
        <el-button class="button" type="primary" @click="fullscreen = !fullscreen">
          <YTIcon
            style="color: #FFFFFF;margin-right: 10px;"
            @click="fullscreen = !fullscreen"
            :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
          />
          <span v-text="fullscreen ? '全屏收起' : '全屏编辑'" />
        </el-button>
        <div>
          <el-button class="button" @click="choiceQuestions = false">取 消</el-button>
          <el-button class="button" type="primary" @click="addSuccess()" :loading="createLoading">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import courseApi from '@api/course'
import Manual from '@/components/manage/exam/exam/newPaper/Manual'
import YTIcon from '@components/common/YTIcon'
import SubjectShow from '@components/manage/course/SubjectShow'
import practiceApi from '@api/practice'
import paperApi from '@api/paper'
export default {
  name: 'NewPractice',
  components: { YTIcon, Manual, SubjectShow },
  data() {
    return {
      notQuestionType: 4,
      isPackUp: false,
      createLoading: false,
      dateForm: {
        chapterId: 0,
        courseId: 0,
        type: 0, //类型
        isRepeat: true, //是否重复
        limitTime: null, //时长
        name: '', //名称
        sectionId: null,
        questionList: [], //题目
        score: 0 //总分
      },
      subjectStatus: false,
      choiceQuestions: false,
      fullscreen: false,
      chapter: null,
      status: false,
      options: [],
      paramData: null,
      disabled: false
    }
  },
  computed: {
    setHeight() {
      if (this.isPackUp) {
        return 'calc(100% - 46px)'
      } else {
        return this.dateForm.type ? 'calc(100% - 346px)' : 'calc(100% - 266px)'
      }
    }
  },
  mounted() {
    const data = this.$handleParams('NewPractice')
    this.paramData = data
    this.getSections(data.chapter)
    if (data.section) {
      this.disabled = true
      this.getManualInfo(data.section.practiceId)
    }
  },
  methods: {
    //选择题目
    async selectQuestions() {
      this.choiceQuestions = true
      this.$nextTick(() => {
        //调用选择题目内部方法
        const array = this.$refs.SubjectShow.questionList
        if (array.length) {
          this.$refs.manual.questionConformity(array)
        }
      })
    },
    //获取练习信息
    getManualInfo(data) {
      practiceApi.getManualInfo(data).then(res => {
        if (res.code === 0) {
          Object.assign(this.dateForm, res.res)
          this.publicPaperInfo(res.res.paperId)
        }
      })
    },
    //获取试卷信息
    publicPaperInfo(paperId) {
      paperApi.publicPaperInfo(paperId).then(res => {
        if (res.code === 0) {
          this.subjectStatus = true
          this.$refs['SubjectShow'].setInfo(res.res)
        }
      })
    },
    // 选择完题目之后
    async addSuccess() {
      let questionList = this.$refs['manual'].editorPageExamQuestions()
      if (Array.isArray(questionList)) {
        this.choiceQuestions = false
        this.subjectStatus = true
        await this.$refs['SubjectShow'].setList(questionList)
      }
    },
    //获取小节名称
    getSections(data) {
      this.chapter = data
      this.dateForm.chapterId = data.chapterId
      this.dateForm.courseId = data.courseId
      courseApi.getSections(data.chapterId).then(res => {
        if (res.code === 0) {
          this.options = res.res
        }
      })
    },
    submitQuestion() {
      const subjectList = this.$refs.SubjectShow.questionList
      const totalScore = this.$refs.SubjectShow.totalScore
      this.$refs.validateForm.validate(vaild => {
        if (vaild) {
          if (subjectList.length) {
            this.dateForm.score = totalScore
            for (const datum of subjectList) {
              for (const datumElement of datum.questionForms) {
                this.dateForm.questionList.push({
                  points: datumElement.score,
                  questionId: datumElement.id
                })
              }
            }
            this.createLoading = true
            if (this.paramData.section) {
              practiceApi
                .modifyPracticeManual(this.dateForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('手动练习修改成功')
                    this.createLoading = false
                    this.$router.push({
                      name: 'courseDetail',
                      params: {
                        courseId: this.chapter.courseId
                      }
                    })
                  }
                })
                .finally(() => {
                  this.createLoading = false
                })
            } else {
              practiceApi
                .addPracticeManual(this.dateForm)
                .then(res => {
                  if (res.code === 0) {
                    this.$message.success('发布成功')
                    this.createLoading = false
                    this.$router.push({
                      name: 'courseDetail',
                      params: {
                        courseId: this.chapter.courseId
                      }
                    })
                  }
                })
                .finally(() => {
                  this.createLoading = false
                })
            }
          } else {
            this.$message.warning('试题不能为空')
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.yt-main {
  height: 100%;
  background: #f8f8f8;
  text-align: left;
  white-space: nowrap;
  .yt-breadcrumb-wrapper {
    min-width: 350px;
  }
  .add-info {
    height: 100%;
    background: #f8f8f8;
    border-radius: 6px;
    padding: 0;
    .add-top {
      background: #ffffff;
      border-radius: 6px;
      .yt-form {
        padding-right: 60px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        ::v-deep .el-form-item {
          width: 50%;
        }
        ::v-deep .el-input,
        .el-select {
          width: 350px;
        }
      }
      .question-button {
        width: 80px;
        height: 38px;
        background: #ffffff;
        padding: 9px 12px;
      }
    }
    .title {
      .flexStyle(flex, space-between, center);
      padding: 10px 20px;
      border-bottom: 1px solid #eeeeee;
      .button {
        .flexStyle(flex, center, center);
        width: 53px;
        height: 25px;
        background: #f7f7f7;
        border: 1px solid #e6e6e6;
        opacity: 1;
        border-radius: 13px;
        user-select: none;
        cursor: pointer;
        margin: 0;
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #888888;
          margin-right: 4px;
        }
        svg {
          transition: all 1s ease-in-out;
          fill: #888888;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000000;
    }
    .add-fun {
      display: none;
      flex-direction: column;
      justify-content: space-between;
      .subject-list {
        overflow-y: auto;
      }
      ::v-deep .el-button--default {
        box-shadow: none;
      }
    }
  }
  .button {
    text-align: center;
    margin-top: 20px;
  }
  .selectDialog {
    ::v-deep .el-dialog__body {
      padding: 0;
    }
  }
}

.question-choose-dialog {
  ::v-deep .el-dialog {
    .el-dialog__body {
      height: 80vh;
      padding: 0;
    }
    .el-dialog__footer {
      .footer {
        .flexStyle(flex);
        margin: 0 60px;
      }
    }
  }
}
</style>
